export const PageNotFound = ()=>{
    return <div className="main__content" style={{marginTop: '1rem'}}>
        <div className="container pb-4">
            <div className="row justify-content-center">
                <div className="col-md-9">
                    <h1>Page not found</h1>
                </div>
            </div>
        </div>
    </div>
}